import { Button, UploadFile, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import api from 'api';
import dayjs from 'dayjs';
import { RoomAccess, RoomData, RoomMark } from 'components/RoomSettings';
import SettingsLoader from 'components/Sceletons/SettingsLoader';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IDataRoom, ISettings } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import classes from './Settings.module.scss';
import { createSettingLog } from './createSettingLog';
import { setRooms } from 'store/slices/userDataSlice';

export default function Settings() {
  const [hasFile, setHasFile] = React.useState<boolean>(true);
  const [isUpdateLoading, setIsUpdateLoading] = React.useState<boolean>(false);
  const [settings, setSettings] = React.useState<ISettings>({
    agreement_enable: true,
    name: '',
    description: '',
    // custom_url: '',
    action: 'nda_disable',
    watermark_enabled: false,
  });
  const [isAvailableToUpdate, setIsAvailableToUpdate] = React.useState(false);
  const [status, setStatus] = React.useState<string>('success');
  const agreementRefs = React.useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { dataRoom, isLoadingRooms } = useAppSelector((state) => state.dataRoom);
  const { userPermissions, userAndRoomStatus, userData, isLoadingUserPermissions} = useAppSelector((state) => state.userData);

  React.useEffect(() => {
    if (userData?.id && !userAndRoomStatus) {
      if (!userPermissions?.can_edit_room_settings) {        
        navigate(`/room/${dataRoom?.custom_url || dataRoom?.id || params.room}/${ localStorage.getItem('previousPage') || 'documents'}`);
      }
    }
  }, [userPermissions, userAndRoomStatus]);

  React.useEffect(() => {
    dispatch(setSelectedKeys(['settings']));
  }, []);

  React.useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      name: dataRoom?.name || '',
      description: dataRoom?.description || '',
      action: dataRoom?.agreement_enable ? 'nda_enable' : 'nda_disable',
      // custom_url: dataRoom?.custom_url || '',
      agreement_enable: dataRoom?.agreement_enable,
      agreement_file: dataRoom?.agreement_enable
        ? { name: dataRoom?.agreement_file, uid: dataRoom?.agreement_id, type: 'document' }
        : undefined,
      watermark_enabled: dataRoom?.watermark_enabled,
    }));
  }, [dataRoom]);

  React.useEffect(() => {
    dataRoom && onFieldsChange(dataRoom, settings)
  }, [settings])

  const onChangeSettings = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setSettings((prev) => ({ ...prev, [target.name]: target.value }));
  };

  const setFile = (file: UploadFile[]) => {
    setSettings((prev) => ({ ...prev, agreement_file: file[0] }));
  };

  const onFieldsChange = (dataRoom: IDataRoom, settings: ISettings) => {
    let isSomeChanged = false;
    for (let field in settings) {
      const exceptions = ['action', 'agreement_enable', 'agreement_file'];
      if (!exceptions.includes(field)) {
        const was = dataRoom[field as keyof typeof dataRoom];
        const becomed = settings[field as keyof typeof settings];
        // eslint-disable-next-line eqeqeq
        if (becomed != was) {
          isSomeChanged = true;
        }
      }
      //проверка для agreement_file
      if (settings.agreement_file?.name && settings.agreement_file?.name !== dataRoom.agreement_file) {
        isSomeChanged = true;
      }
    }
    setIsAvailableToUpdate(isSomeChanged);
  }

  const setAgreement = () => {
    setSettings((prev) => {
      if (prev.agreement_enable) {
        return { ...prev, agreement_enable: !prev.agreement_enable, agreement_file: undefined, action: 'nda_disable' };
      }
      return { ...prev, agreement_enable: !prev.agreement_enable, action: 'nda_enable' };
    });
  };

  const onToggleWatermark = (isEnableWatermark: boolean) => {
    setSettings((prev) => ({ ...prev, watermark_enabled: isEnableWatermark }));
  };

  const onSave = async () => {
    if (settings.agreement_enable && !settings.agreement_file) {
      message.warning(t('Settings.error.fileUpload'));
      setHasFile(false);
      return;
    }
    setIsUpdateLoading(true);
    try {
      const agreementIsChanging = settings.agreement_enable !== dataRoom?.agreement_enable;
      const action = agreementIsChanging ? settings.action : 'update';
      const isThisNewFile = dataRoom?.agreement_file !== settings.agreement_file?.name;
      const isNeedAddNewDocument =
        (settings.agreement_file && agreementIsChanging) || (settings.agreement_file && isThisNewFile);

      const details = createSettingLog(dataRoom!, settings);

      const formDataSettings = new FormData() as any;
      const formDataAgreement = new FormData() as any;
      formDataSettings.append('name', settings.name);
      formDataSettings.append('description', settings.description);
      formDataSettings.append('details', JSON.stringify(details));

      if (isNeedAddNewDocument) {
        formDataAgreement.append('room_id', dataRoom?.id);
        formDataAgreement.append('agreement_enable', String(settings.agreement_enable));
        formDataAgreement.append('agreement_file', settings.agreement_file as RcFile);
        await api.setRoomAgreement(formDataAgreement)
      }

      settings.watermark_enabled !== dataRoom?.watermark_enabled &&
        (await api.toggleRoomWatermark({ enable: settings.watermark_enabled!, id: dataRoom?.id! }));
        
      const response = await api.setRoomSettings(
        formDataSettings,
        dataRoom?.id!,
        action,
        settings.agreement_file?.name || ''
      );
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const newRooms = userData?.available_rooms.map((room) =>
        room.id === response.data.id
          ? {
            ...response.data,
            created_at: dayjs(response.data.created_at).unix() * 1000,
            last_action_timestamp: UTCDate,
            permissions: room.permissions,
          }
          : room
      ).sort((a,b) => b.last_action_timestamp - a.last_action_timestamp);
      dispatch(setRooms(newRooms || []));
      navigate(`/room/${response.data?.custom_url || response.data?.id}/settings`);
      dispatch(setDataRoom({...response.data, user_status: 2}));
      message.success(
        `${t('Settings.success.settingsUploaded.before')} ${response.data?.name}
        ${t('Settings.success.settingsUploaded.after')}`
      );
    } catch (err) {
      message.error(
        `${t('Settings.error.settingsUpload.before')} ${dataRoom?.name} 
        ${t('Settings.error.settingsUpload.after')}`
      );
    } finally {
      setHasFile(true);
      setIsUpdateLoading(false);
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.card_group}>
        {isLoadingRooms || isLoadingUserPermissions || userAndRoomStatus !== '' ? (
          <SettingsLoader />
        ) : (
          <>
            <RoomData t={t} setSettings={onChangeSettings} />
            {/* <RoomAddress
              custom_url={settings.custom_url}
              setStatus={setStatus}
              setSettings={onChangeSettings}
              t={t}
            /> */}
            <RoomMark t={t} watermark_enabled={settings.watermark_enabled!} setWaterMark={onToggleWatermark} />
            <RoomAccess
              t={t}
              file={settings.agreement_file}
              setFile={setFile}
              agreement_enable={settings.agreement_enable}
              setAgreement={setAgreement}
              agreementRefs={agreementRefs}
              hasFile={hasFile}
              setHasFile={setHasFile}
              action={settings.action}
            />
            {/* <RoomInvitation
              t={t} invitations={invitations}
              setInvitations={setInvitations}
            /> */}
            <Button
              loading={isUpdateLoading}
              onClick={onSave}
              className={classes.saveButton}
              type='primary'
              disabled={!settings.name || status === 'error' || !isAvailableToUpdate}
            >
              {t('main.saveButton')}
            </Button>
          </>
        )}
      </div>

      {isUpdateLoading && <UploadingComponent />}
    </div>
  );
}
