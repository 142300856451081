import { Empty, Tabs, TabsProps } from 'antd';
import MessangerLoader from 'components/Sceletons/MessangerLoader';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsOpenCreateModal, setListStatus } from 'store/slices/chatsSlice';
import Messanger from './Messanger';
import classes from './Messanger.module.scss';

type Props = {
  onSendMessage: (event: any) => void;
};

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => {
  return <DefaultTabBar {...props} style={{ display: 'none' }} />;
};

export default function MessengerWithTabs({ onSendMessage }: Props) {
  const { roomAllChats, chatData, currnetChatLoading, selectedChat, globalChats } = useAppSelector((state) => state.chat);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const onChatUsersOpen = () => {
    dispatch(setListStatus('groupList'));
    dispatch(setIsOpenCreateModal(true));
  };

  const chatsList = useMemo(() => [...globalChats, ...roomAllChats], [globalChats, roomAllChats]);

  const items = chatsList.map((chat) => ({
    key: chat.id,
    label: <div className={classes.messanger_tab_item}>{chat.title}</div>,
    children:
      currnetChatLoading && selectedChat?.id === chat.id ? (
        <MessangerLoader />
      ) : (
        <Messanger
          t={t}
          onChatUsersOpen={onChatUsersOpen}
          onSendMessage={onSendMessage}
          chatGroups={chatData[chat.id]?.grouped}
          chatInfo={selectedChat}
        />
      ),
  }));

  return (
    chatsList.length ? <Tabs
      popupClassName={classes.destroy}
      className={classes.messanger_tabs}
      items={items}
      tabPosition='left'
      renderTabBar={renderTabBar}
      activeKey={selectedChat?.id}
      moreIcon={null}
    /> : <Empty description={t('Chats.unselectedState')} className={classes.empty}  />
  );
}
