import { Form, Input, message, Modal } from 'antd';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import classes from '../SingingTab.module.scss'
import api from 'api';
import { setSigningInfo, setSigningState } from 'store/slices/signingSlice';
import { setDocuments } from 'store/slices/dataDocumentsSlice';
import dayjs from 'dayjs';

type DeclineSigningModalProps = {
  open: boolean;
  onCancel: () => void;
  documentId: string;
  documentPermissions: string[];
};

const DeclineSigningModal = ({
  open,
  onCancel,
  documentId,
  documentPermissions
}: DeclineSigningModalProps) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [form] = Form.useForm();

  const { userData } = useAppSelector((store) => store.userData);
  const { documents, folders, selectedDocument } = useAppSelector((store) => store.documents);
  const { signing, needSigningFromUsers, signingInfo, mySigning } = useAppSelector((store) => store.signing);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onSubmit = async (values: { comment: string }) => {
    setIsLoading(true);
    try {
      await api.declineSigning({ comment: values.comment, document_id: documentId });

      const author = {
        name: `${userData?.first_name} ${userData?.last_name}`,
        id: userData?.id,
        email: userData?.email,
      };
      dispatch(setSigningInfo({ ...signingInfo!, status: 1, comment: values.comment, author }));

      const changedMySigning = mySigning
        ? { ...mySigning, signing_status: false, signing_date: dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS') }
        : null;
      const newNeedSigningFromUsers = needSigningFromUsers.map((signingFromUsers) =>
        signingFromUsers.user_id === changedMySigning?.user_id ? changedMySigning : signingFromUsers
      );

      dispatch(
        setSigningState({
          mySigning: changedMySigning,
          needSigningFromUsers: newNeedSigningFromUsers,
          signing: signing.map((sign) => ({
            ...sign,
            users: sign.users?.map((user) =>
              user.user_id === changedMySigning?.user_id ? changedMySigning : user
            ),
          })),
        })
      );

      dispatch(setDocuments({
        documents: documents.map((document) => {
          return document.id === selectedDocument!.id
            ? { ...document, signing_status: 1, permissions: documentPermissions }
            : document;
        }),
        folders,
      }));
      onCancel();
      message.success(t('Documents.details.signing.successDeclined'));
    } catch (e) {
      message.error(t('Documents.details.signing.failedDecline'));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title={t('Documents.details.agreement.message')}
      open={open}
      onCancel={onCancel}
      okText={t('Documents.details.agreement.declineButton')}
      okButtonProps={{ className: classes.declineButton }}
      onOk={form.submit}
      confirmLoading={isLoading}
    >
      <Form
        onFinish={onSubmit}
        className={classes.decline}
        form={form}
      >
        <Form.Item
          name='comment'
          rules={[{ required: true, message: t('Documents.details.agreement.textArea') }]}
          className={classes.decline_item}
        >
          <Input.TextArea
            placeholder={t('Documents.details.agreement.textPlacehoder')}
            autoSize={{ minRows: 4, maxRows: 8 }}
            count={{
              show: true,
              max: 500,
              exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
            }}
          />
        </Form.Item>
      </Form>
      {isLoading ? <UploadingComponent /> : null}
    </Modal>
  );
}

export default DeclineSigningModal;
