import { ConfigProvider, Layout, message } from 'antd';
import en_US from 'antd/es/locale/en_US';
import ru_RU from 'antd/es/locale/ru_RU';
import es_ES from 'antd/es/locale/es_ES';
import api from 'api';
import { HeaderComponent, Navigation } from 'components/Header';
import SessionComponent from 'components/SessionComponent/SessionComponent';
import WorkerComponent from 'components/SessionComponent/WorkerComponent';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import mainPages from 'helpers/mainPages';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchChatHistory, fetchChatId, fetchGlobalChats } from 'store/reducers/chatCreator';
import { fetchRoom } from 'store/reducers/roomsCreator';
import { fetchUserData, fetchUserPermissions, prepareRooms } from 'store/reducers/userDataCreator';
import { setCurrentFolder } from 'store/slices/dataDocumentsSlice';
import { setFoldersForPermissions, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { setUserAndRoomStatus, setUserAvailableRooms, setUserData } from 'store/slices/userDataSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { setNeedToUpdateRooms } from 'store/slices/dataRoomSlice';
import SideBar from '../../components/SideBar/SideBar';
import classes from './Main.module.scss';
import cn from 'classnames';
import { fetchGlobalUsers } from 'store/reducers/usersGroupCreator';

const languageConfigs = { ru: ru_RU, en: en_US, kg: en_US, es: es_ES };
export const onlyUserPages = ['profile', 'organization', 'rooms', 'schedule', 'report', 'clients', 'organization/clients', 'billing'];

export default function Main() {  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const { userData, userAndRoomStatus, selectedRoom } = useAppSelector((store) => store.userData);
  const { isResizing } = useAppSelector((store) => store.windowState);
  const { dataRoom, needToUpdateRooms } = useAppSelector((store) => store.dataRoom);  
  const { chatData } = useAppSelector((state) => state.chat);

  const currentRoomPath = dataRoom?.custom_url || dataRoom?.id;
  const { pathname: location, search } = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  
  const previousPage = params.room && location.replace(`/room/${params.room}/`,'').split('/')[0]
  const isChatPrevious = previousPage === 'chat' ? location.replace(`/room/${params.room}/`,'').split('/')[1] : 'document';
  const selectedKeys = isChatPrevious ? `${previousPage}/${isChatPrevious}` : String(previousPage);

  const isNeedToDisplayRoomNavigation = !onlyUserPages.includes(selectedKeys);  
  
  const currentLanguageConfig = languageConfigs[i18n.resolvedLanguage as keyof typeof languageConfigs];

  const navigateToPage = (currentPath: string) => navigate(currentPath);
  const navigateToConfirm = () => navigate(`/room/${currentRoomPath}/nda`);
  const navigateRoomFromStatus = [navigateToPage, navigateToConfirm, navigateToPage, navigateToPage];

  const cases = {
    pendingUserData: async () => {      
      if (searchParams.get('type') === 'redirect') {
        localStorage.setItem('previousRoomId', params.room!);
        localStorage.removeItem('previousFolderPath');
        localStorage.removeItem('prevTab');
      };

      dispatch(setSelectedKeys([selectedKeys]));
      await dispatch(fetchUserData({}));
    },

    emptyDataRoom: () => {
      navigate('/rooms');
    },

    fulfilledUserData: async () => {
      const previousPage = localStorage.getItem('previousPage') || 'rooms';
      const chatId = params?.chatId || localStorage.getItem('previousChatId')
      if (!selectedRoom && mainPages.includes(previousPage)) {
        const pathGlobalChat = previousPage === 'global-chats' && chatId ? `${previousPage}/${chatId}` : previousPage;
        previousPage ? navigate(pathGlobalChat) : navigate('/rooms');
        previousPage ? dispatch(setSelectedKeys([previousPage])) : dispatch(setSelectedKeys(['rooms']));
      } else if (!mainPages.includes(previousPage)){        
        await dispatch(fetchUserPermissions({ idRoom: selectedRoom?.id! }));
        await dispatch(fetchRoom({ id: selectedRoom?.id! }));
        dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      }
      // else {
      //   navigate(previousPage);
      //   dispatch(setSelectedKeys([previousPage]));
      // }
      dispatch(fetchGlobalUsers());
      dispatch(fetchGlobalChats({ ownerId: userData?.id!, chatId: chatId || null }));
    },

    fulfilledDataRoom: async () => {
      const folderIdFromMemory = params.folderId || localStorage.getItem('previousFolderId');
      // запись пути папки (из URL или previousFolderId) в LocalStorage как 'previousFolderPath';

      if (folderIdFromMemory && dataRoom) {
        const folder = await api.getFolderById(folderIdFromMemory, dataRoom?.id!)
        localStorage.removeItem('previousFolderId');
        localStorage.setItem('previousFolderPath', JSON.stringify(folder.data.folder_path?.reverse()))
      }

      const previousFolderPath: any[] = JSON.parse(localStorage.getItem('previousFolderPath') || '[]');
      const previousPage = localStorage.getItem('previousPage') || 'rooms';
      dispatch(setCurrentFolder(previousFolderPath))
      dispatch(fetchChatId({ roomId: dataRoom?.id!, ownerId: userData?.id!, chatId: params?.chatId || localStorage.getItem('previousChatId') ||  null }));
      const lastFolderId = previousFolderPath[previousFolderPath.length - 1]?.id;
      const chatId = localStorage.getItem('previousChatId');

      const entityId = previousPage === 'documents' && lastFolderId
        ? `/${lastFolderId}`
        : chatId
          ? `/${chatId}`
          : '';
      
      // if (chatId && chatData[chatId] && !chatData[chatId].isLoadedHistory) {
      //   await dispatch(fetchChatHistory({ chat_id: chatId, room_id: dataRoom?.id! }));
      // }

      const locationWithId = !currentRoomPath
        ? '/rooms'
        : `/room/${currentRoomPath}/${previousPage}${entityId}`;

      localStorage.removeItem('previousChatId');

      const navigatePath = onlyUserPages.includes(previousPage)
        ? previousPage
        : locationWithId;

      !search && navigateRoomFromStatus[dataRoom?.user_status! || 0](navigatePath);
      getMyPermissions();
    },
  };

  React.useEffect(() => {
    return () => {
      dispatch(setUserData(null));
      dispatch(setUserAndRoomStatus('pendingUserData'));
    };
  }, []);

  React.useEffect(() => {
    const availableRoomsUpdate = userData && setInterval( async () => {
      if (needToUpdateRooms && userData) {
        const userInfo = await api.getUserInfo();
        const available_rooms = prepareRooms(userInfo.data);
        dispatch(setUserAvailableRooms(available_rooms));
        dispatch(setNeedToUpdateRooms(false));
      }
    }, 10000);
    
    return () => {
      availableRoomsUpdate && clearInterval(availableRoomsUpdate);
    };
  }, [userData, needToUpdateRooms]);

  React.useEffect(() => {
    try {
      (cases[userAndRoomStatus as keyof typeof cases] || function () {})();
    } catch(err) {
      message.error(t('Header.error.personalData'));
    }
  }, [userAndRoomStatus]);

  const getMyPermissions = async () => {
    const { data: foldersPermissionsReq } = await api.getFoldersPermissions({ room_id: dataRoom?.id! });

    const onlyFolders = foldersPermissionsReq.filter((el: any) => el.id !== 0);
    const rootFolder = foldersPermissionsReq.find((el: any) => el.id === 0);
    dispatch(
      setUserPermissions({ documents: [], folders: onlyFolders[0] ? onlyFolders : dataRoom?.folders, rootFolder })
    );
    dispatch(setUserAndRoomStatus(''));
    dispatch(
      setFoldersForPermissions(onlyFolders.map((folder) => ({ ...folder, key: `folder ${folder.id}`, type: 'folder' })))
    );
  };  

  return (
    <Layout className={cn(classes.mainDiv, isResizing ? classes.resizing : 'null')}>
      <ConfigProvider locale={currentLanguageConfig}>
        <HeaderComponent />

        <Layout>

          <SideBar />

          <div className={classes.outletWrap}>
            {isNeedToDisplayRoomNavigation && <Navigation />}
            <Outlet />
          </div>

          {userData && (window.SharedWorker ? <WorkerComponent /> : <SessionComponent />)}
          
          {/* {window.location.pathname === '/' && <UploadingComponent />} */}

        </Layout>
      </ConfigProvider>
    </Layout>
  );
}
