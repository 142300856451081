import React from 'react';
import { useNavigate } from 'react-router-dom';

const WrongAddress = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.removeItem('previousFolderId');
    localStorage.removeItem('previousRoomId');
    localStorage.removeItem('previousFolderPath');
    localStorage.removeItem('prevTab');
    localStorage.removeItem('previousPage');
    navigate('/rooms');
  }, []);

  return (
    <main className={'errorPage'}><p>Неверный адрес</p></main>
  );
}

export default WrongAddress;
