import api from 'api';
import { socketUrl } from 'api/ws';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchChatData, { fetchChatInfo, fetchUnviewedAmount } from 'store/reducers/chatCreator';
import fetchStatusDocuments, {
  fetchDeleteDocument,
  fetchDeleteFolder,
  fetchDocumentMove,
  fetchDocumentUpdate,
  fetchFoldersPermissionsUpdate,
} from 'store/reducers/documentsSocketsCreator';
import { fetchRoomNotifications } from 'store/reducers/roomsCreator';
import { clearAllChatData, setChatMessage, setChatStatus, setIsLoadedSession } from 'store/slices/chatsSlice';
import { setDataRoom, setNeedToUpdateRooms } from 'store/slices/dataRoomSlice';
import { deleteCookie, getCookie, setCookie } from 'helpers/cookies';
import { useNavigate } from 'react-router-dom';
import { setCurrentFolder, setDocuments } from 'store/slices/dataDocumentsSlice';
import { resetUserPermissions, setUserData } from 'store/slices/userDataSlice';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import { setDataForPermissionTable, setUserPermissions, setFoldersForPermissions } from 'store/slices/dataPermissionsSlice';
import { resetOrgsAndSides } from 'store/slices/dataSidesSlice';

export default function WorkerComponent() {
  const [isPausedWS, setIsPausedWS] = React.useState(false);
  const workerRef = React.useRef<SharedWorker | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const locale = i18n.language || 'en';

  const { userData } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { chatStatus, chatMessage, selectedChat, isLoadedSession } = useAppSelector((store) => store.chat);
  const { foldersForPermissions } = useAppSelector((store) => store.permissions);

  const clearRedux = () => {
    dispatch(setDataRoom(null));
    dispatch(clearAllChatData());
    dispatch(setDocuments({ documents: [] }));
    dispatch(resetUserPermissions());
    dispatch(setUserAndData({ users: [], dataUserTable: [] }));
    dispatch(setGroups([]));
    dispatch(setGroupChoosen(null));
    dispatch(setCurrentFolder([]));
    dispatch(setDataForPermissionTable({ documents: [] }));
    dispatch(setUserPermissions(null));
    dispatch(setUserData(null));
    dispatch(resetOrgsAndSides());
    localStorage.removeItem('previousFolderId');
    localStorage.removeItem('previousRoomId');
    localStorage.removeItem('previousFolderPath');
    localStorage.removeItem('prevTab');
    localStorage.removeItem('previousPage');
  };

  const chatCases = {
    pending: (message: any) => {},
    send: (message: any) => {
      workerRef.current?.port.postMessage({ event: 'chat', message });
      dispatch(setChatStatus('pending'));
      dispatch(setChatMessage({ id: '', status: 'pending', message: '' }));
    },
  };

  const workerMessageListeners = React.useMemo(() => {
    return {
      'auth:get': (message: any) => {
        dispatch(setIsLoadedSession(true));
        console.log('Session is authed', message);
      },

      new_chat: (data: any) => {
        console.log('new chat created -', data);
        dispatch(fetchChatInfo({ chatId: data.new_chat_id, ownerId: userData?.id! }));
      },

      new_global_chat: (data: any) => {
        console.log('new global chat created -', data);
        dispatch(fetchChatInfo({ chatId: data.new_chat_id, ownerId: userData?.id! }));
      },

      document_create: async (data: any) => {
        const response = await api.getDocumentById(data.document_id);
        dispatch(fetchStatusDocuments({ type: 'replace', newDocuments: [response.data] }));
        console.log('Get session message:', data);
      },

      folder_create: async (data: any) => {
        const response = await api.getFolderOfFolder(data.folder_id, data.room_id);
        console.log('Get session message:', data);
        dispatch(
          fetchStatusDocuments({
            type: 'replace',
            newFolder: response.data,
            parent_folder_id: data.parent_folder_id,
          })
        );

        dispatch(setFoldersForPermissions([...foldersForPermissions, response.data]));
      },

      document_delete: async (data: any) => {
        console.log('Document deleted:', data);
        dispatch(fetchDeleteDocument(data));
      },

      folder_delete: async (data: any) => {
        console.log('Folder deleted:', data);
        dispatch(fetchDeleteFolder(data));
      },

      document_move: async (data: any) => {
        console.log('Document moved:' ,data);
        dispatch(fetchDocumentMove(data));
      },

      document_update: async (data: any) => {
        console.log('Document updated:', data);
        dispatch(fetchDocumentUpdate(data));
      },

      permissions_update: async (data: any) => {
        console.log('Document updated:', data);
        dispatch(fetchFoldersPermissionsUpdate(data));
      },

      notification_create: async (message: any) => {
        console.log('Notification created:', message);
        dispatch(fetchRoomNotifications({ locale }));
      },

      rooms_update: async (data: any) => {
        console.log('Message:', data);
        dispatch(setNeedToUpdateRooms(true));
      },

      chat: async (data: any) => {
        if (data.email !== userData?.email && data.chat_id !== selectedChat?.id) {
          dispatch(fetchUnviewedAmount({ data, count: 1 }));
        }
        dispatch(fetchChatData({ data, type: 'replace' }));
        console.log('Get session message:', data);
      },

      init: (data: any) => {
        console.log('init -', data);
      },

      logout: (data:any) => {
        deleteCookie('athena-token');
        deleteCookie('refresh_token');
        clearRedux();
        navigate('/login');
      },

      disconnect: () => {
        setCookie('sid', '', 1);
        setIsPausedWS(true);
        console.log(`The session is closed at: ${new Date().toLocaleString()}`);
      },
    };
  }, [locale]);

  React.useEffect(() => {
    if (!isPausedWS) {
      // @ts-ignore
      workerRef.current = new SharedWorker(new URL('../../utils/Worker.ts', import.meta.url));
      workerRef.current.port.start();
      workerRef.current.port.postMessage({ event: 'connect', url: socketUrl });
      workerRef.current.port.onmessage = (message: any) => {
        if (message.data.event === 'connect') {
          console.log(`The session is open at: ${new Date().toLocaleString()}`);
          setCookie('sid', message.data.sid || '', 1);

        } else {
          (workerMessageListeners[message.data?.event as keyof typeof workerMessageListeners] || function () {})(
            message.data
          );
        }
      };

      workerRef.current.port.postMessage({ event: 'auth:post', userId: userData?.id });
      window.addEventListener('beforeunload', () => {
        workerRef.current?.port.postMessage({ event: 'close' });
      });
    }

    return () => {
      if (!getCookie('athena-token')) workerRef.current?.port.postMessage({ event: 'logout' });
      if (isLoadedSession) workerRef.current?.port.close();
    };
  }, [isPausedWS]);

  React.useEffect(() => {
    if (dataRoom?.id && isLoadedSession) {
      workerRef?.current!.port.postMessage({ event: 'init', roomId: dataRoom?.id });
    }
  }, [dataRoom?.id, isLoadedSession]);

  React.useMemo(
    () =>
      document.addEventListener('click', function () {
        if (isLoadedSession) {
          setIsPausedWS(false);
        }
      }),
    [isLoadedSession, isPausedWS]
  );

  React.useEffect(() => {
    const data = {
      room_id: dataRoom?.id,
      message: chatMessage.message,
      chat_id: selectedChat?.id,
      created_at: chatMessage.id,
    };

    chatCases[chatStatus](data);
  }, [chatStatus]);

  // Функционал прерывания сессии при неактивной вкладке браузера (сворачивание браузера/переход на другую вкладку)
  // React.useMemo(() => document.addEventListener('visibilitychange', function(){
  //   if (document.hidden && !isPausedWS && sessionChanel.current?.readyState === 1){
  //     setIsPausedWS(true);
  //     // console.log(`Сlosed: ${new Date().toLocaleString()}`);
  //     // sessionChanel.current?.send(`Сlosed: ${new Date().toLocaleString()}`);
  //     sessionChanel.current?.close();
  //   } else if (!document.hidden && isPausedWS) {
  //     setIsPausedWS(false);
  //   }
  // }), [sessionChanel.current?.readyState]);

  // Ручное прерыване канала вебсокета для тестирования
  // const closeWS = () => {
  //   sessionChanel.current?.close();
  //   // console.log(`Сlosed: ${new Date().toLocaleString()}`);
  //   // setIsPausedWS(true);
  // }
  // return (
  //   !isPausedWS
  //     ? <button style={{position: 'absolute'}} onClick={closeWS}>{ 'Остановить соединение' }</button>
  //     : null
  // );

  return null;
}
