import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import PDFViewer from 'components/Webviewer/Viewer/PDFViewer';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { useTranslation } from 'react-i18next';
import RowLoader from 'components/Sceletons/RowLoader';
import SignatureRulesModal from './SigningComponents/SignatureRulesModal';
import SigningCard from './SigningCard/SigningCard';
import NotPartisipant from './SigningComponents/NotPartisipant';
import CheckboxWithRules from './SigningComponents/CheckboxWithRules';
import SigningCertificate from './SigningComponents/SigningCertificate';
import classes from './SingingTab.module.scss';

type SingingTabProps = {
  closeDetailsModal: () => void;
};

const SingingTab = ({closeDetailsModal}: SingingTabProps) => {
  const [isRulesModalOpen, setIsRulesModalOpen] = React.useState<boolean>(false);
  const [isSectionAvailable, setIsSectionAvailable] = React.useState<boolean>(true);
  const [displayingCertificatSigning, setDisplayingCertificatSigning] = React.useState<boolean>(false);

  const { selectedDocument } = useAppSelector((state) => state.documents);
  const { documentUrl, isDocumentLoading } = useAppSelector((state) => state.viewer);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);
  const { signing, isSigningLoading, needSigningFromUsers, signingInfo } = useAppSelector((state) => state.signing);

  const needSigningIdsParticipants = needSigningFromUsers.filter(user => !user.signing_status).map(user => user.user_id);
  const idsParticipants = needSigningFromUsers.map(user => user.user_id);  

  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (signing[0]) {
      setIsSectionAvailable(needSigningIdsParticipants.includes(userData?.id!) && signingInfo?.status !== 1);
      setDisplayingCertificatSigning(idsParticipants.includes(userData?.id!) || userData?.id === signingInfo?.initiator_id);
    };

    return () => {
      setIsSectionAvailable(true);
    }
  }, [signing]);

  React.useEffect(() => {
    setTimeout(() => !userData?.has_sign && setIsRulesModalOpen(true), 1000);
  }, [userData]);

  React.useEffect(() => {
    selectedDocument && dispatch(
      fetchDocumentToViewer({
        token: selectedDocument.token,
        id: dataRoom?.id!,
        entity: selectedDocument.id,
        extension: selectedDocument.type,
        is_agreement: false,
        action: 'view',
        locale: i18n.language,
      })
    );
  }, [selectedDocument?.id]);

  const openRulesModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setIsRulesModalOpen(true);
  };

  return (
    <div>
      {isSectionAvailable
        ? (
          <div className={classes.container}>
            <div className={classes.viewerWrap}>
              {isDocumentLoading || isSigningLoading 
                ? <RowLoader height={1200} width={1000} padding={'0 12px 0 0'}/>
                : <PDFViewer url={documentUrl} isModal={false}/>
              }
            </div>

            <div className={classes.sidebar}>

              {isDocumentLoading || isSigningLoading
                ? <RowLoader height={400} width={400} padding={'0 0 0 0'}/>
                : <SigningCard closeDetailsModal={closeDetailsModal}/>
              }

              <CheckboxWithRules
                openRulesModal={openRulesModal}
                hasSign={userData?.has_sign}
              />
            </div>
          </div>
        )
        : (
          <div>{
            displayingCertificatSigning
            ? <SigningCertificate />
            : <NotPartisipant />
          }</div>
        )
      }

      {isRulesModalOpen && (
        <React.Suspense fallback={<div />}>
          <SignatureRulesModal
            isRulesModalOpen={isRulesModalOpen}
            setIsRulesModalOpen={setIsRulesModalOpen}
            isAlreadyAccept={Boolean(userData?.has_sign)}
          />
        </React.Suspense>
      )}
    </div>
  );
}

export default SingingTab;
